.main {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  height: 100%;
  width: 100%;
  background-color: black;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
