@font-face {
  font-family: "ThisFont";
  src: url("./assets/fonts/domaine-display-bold.woff2") format("woff2"); /* IE9 Compat Modes */
}

.colors {
  color: #373737;
  color: #e5ce72;
  color: #3c8b66;
  color: #b0134b;
  color: #d96c0c;
  color: #000000;
  color: #ffffff;
}

a {
  text-decoration: none;
  color: inherit;
}

h1 {
  font-size: 32px;
  font-weight: 600;
}
h2 {
  font-size: 26px;
  font-weight: 600;
}
h3 {
  font-size: 22px;
  font-weight: 600;
  color: white;
}
h4 {
  font-size: 20px;
  font-weight: 300;
  line-height: 1.4rem;
}
h6 {
  font-size: 13px;
  font-weight: 300;
}
p {
  font-size: 11px;
  font-weight: 300;
}

a {
  text-decoration: none;
}

@media screen and (max-width: 744px) {
  h1 {
    font-size: 32px;
    font-weight: 600;
  }
  h2 {
    font-size: 26px;
    font-weight: 600;
    font-size: 100%;
  }
  h3 {
    font-size: 22px;
    font-weight: 600;
  }
  h4 {
    font-size: 17px;
    font-weight: 200;
  }
  h6 {
    font-size: 13px;
    font-weight: 300;
  }
  p {
    font-size: 8px;
    font-weight: 300;
  }
}

@media screen and (max-width: 744px) {
  h1 {
    font-size: 32px;
    font-weight: 600;
  }
  h2 {
    font-size: 26px;
    font-weight: 600;
    font-size: 100%;
  }
  h3 {
    font-size: 22px;
    font-weight: 600;
  }
  h4 {
    font-size: 14px;
    font-weight: 200;
  }
  h6 {
    font-size: 13px;
    font-weight: 300;
  }
  p {
    font-size: 8px;
    font-weight: 300;
  }
}
