.main {
  height: 100vh;
}
/* .left,
.right {
  flex: 1;
} */

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 90vh;
  overflow: scroll;
  height: 100vh;
}

.left {
  background-color: rgb(39, 39, 39);
  /* position: relative; */
  /* overflow: hidden; */
}

.background {
  width: 100%;
  object-fit: cover;
  transform: scale(1);
  height: 100%;
}

.right {
  background-color: black;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  /* position: relative; */
}

@media screen and (max-width: 430px) {
  .main {
    height: 89vh;
  }
  .container {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 80vh;
    overflow: scroll;
    height: 100vh;
  }
}
