.main {
  /* border: 4px solid red; */
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.box div:nth-child(1) {
  /* border: green solid 2px; */
  /* display: none; */
}
.box{
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
}

.email{
  display: flex;
  gap: 1rem;
  align-items: center;
}