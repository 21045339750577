.main {
  /* border: 2px dashed red; */
  width: 100%;
  padding: 2rem;
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 8;
}

.logo,
.menu {
  flex: 1;
  /* border: 1px dashed orange; */
  /* padding-left: 3rem; */
}

.menu {
  /* padding: 0 3rem; */
  display: none;
}

.burger {
  position: absolute;
  left: 0;
  /* border: 2px dashed orange; */
  margin-left: 2rem;
  font-size: 1.5rem;
  cursor: pointer;
  color: white;
}

.logo {
  display: flex;
  justify-content: center;
}

.svg {
  filter: invert(100%) sepia(0%) saturate(285%) hue-rotate(278deg)
    brightness(117%) contrast(100%);
}

.menuItems {
  padding: 0 3rem;
  display: flex;

  list-style: none;
  gap: 2rem;
  text-transform: uppercase;
  color: white !important;
  font-weight: 600;
  font-family: "Figtree", sans-serif;
  font-size: 0.8rem;
  cursor: pointer;
  /* border: 2px dashed red; */
}

.menuItem {
  color: white !important;
  text-decoration: none;
  /* border: 2px dashed green; */
}

@media screen and (min-width: 744px) {
  .burger {
    display: none;
  }
  .menu {
    display: flex;
    /* border: 1px orange dashed; */
    /* margin-left: 2rem; */
  }
}

@media screen and (max-width: 430px) {
  .logo {
    justify-content: flex-end;
  }
  .main {
    padding: 1rem;
  }
  .burger {
    margin-left: 1rem;
  }
}
