.main {
    /* border: 4px solid red; */
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .box div:nth-child(1) {
    /* border: green solid 2px; */
    /* display: none; */
  }
  .box{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.4rem;
    margin-top: -3rem;
  }

  .box h2{
    font-family: "ThisFont", serif;
    text-transform: uppercase;
  }

  .box h4{
    font-weight: 100;
    color: rgb(184, 184, 184);
  }
  
  .email{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    color: white;
    align-items: center;
  }

  @media screen and (min-width: 744px) {
    .burger {
      display: none;
    }
    .menu {
      display: flex;
      /* border: 1px orange dashed; */
      /* margin-left: 2rem; */
    }
  }
  
  @media screen and (max-width: 430px) {
    .box{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        margin-top: -5rem;
      }
    
      .box h2{
        font-family: "ThisFont", serif;
        text-transform: uppercase;
      }
    
      .box h4{
        font-weight: 100;
        color: rgb(184, 184, 184);
      }
      
      .email{
        display: flex;
        flex-direction: column;
        gap: 0.1rem;
        color: white;
        align-items: center;
      }
  }
  