.main {
  /* background-color: rgb(110, 110, 110); */
  padding: 0 0.6rem;
  border-left: #d86c0d 1px solid;
  color: white;
  display: flex;
  gap: 0.1rem;
  flex-direction: column;
  /* overflow: hidden; */
  cursor: pointer;
  transition: all ease 0.2s;
  width: 170px;
  height: 100%;
  position: relative;
}

.title {
  font-family: "ThisFont", serif;
  padding-bottom: 0.4rem;
  width: 120px;
  margin-top: -2px;
  font-weight: 700;
  text-transform: capitalize;
}

.price{
  position: absolute;
  top: -10px;
  right: 0;
  height: 35px;
  width: 35px;
  background-color: black;
  border: 1px solid grey;
  border-radius: 10000px;
  padding: 1px;
  color: rgb(195, 195, 195);
  font-weight: 100;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.small{
  font-size: 0.5rem;
}

.subtitle {
  font-weight: 300;
  /* font-size: 0.8rem; */
  text-transform: uppercase;
}

.text {
  font-weight: 200;
  text-transform: uppercase;
  color: grey;
}

.inactive {
  opacity: 0.5;
  border-left: 1px solid grey;
}

.color1 {
  border-left: #d86c0d 1px solid;
}
.color2 {
  border-left: #e5ce72 1px solid;
}
.color3 {
  border-left: #3c8b66 1px solid;
}
.color4 {
  border-left: #b0134b 1px solid;
}
.color5 {
  border-left: #d86c0d 1px solid;
}
.color6 {
  border-left: #b0134b 1px solid;
}

@media screen and (max-width: 430px) {
  .main {
    width: 100%;
    /* width: 100%; */
    margin-left: 0.5rem;
  }
  .title {
    line-height: 20px;
    margin-top: -2px;
    font-size: 1.1rem;
    width: 360px;
  }
  .text {
    font-size: 0.7rem;
  }
}
