.main {
  /* border: 2px dashed red; */
  width: 100%;
  padding: 2rem;
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 8;
  bottom: 0;
}

.socials,
.address {
  flex: 1;
  /* border: 1px dashed orange; */
  /* padding-left: 3rem; */
}

.newAdd {
  display: none;
}
.newAdd2 {
  display: none;
}

.socials {
  /* position: absolute; */
  cursor: pointer;
  display: flex;
  gap: 1rem;
  /* transform: translateX(-50%); */
  font-size: 1.5rem;
  justify-content: center;
  align-items: center;
}

.email {
  font-size: 1rem;
}

.emails{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  /* border: 5px orange solid; */
}
.emailCont{
  display: flex;
  align-items: center;
  gap: 0.5rem;
 
}

.address {
  /* position: absolute; */

  /* transform: translateX(-50%); */
  font-size: 1.5rem;
  /* border: 2px solid red; */
  text-align: center;
  font-size: 1rem;
  width: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.white {
  color: white;
}

@media screen and (max-width: 430px) {
  .address {
    display: none;
  }
  .socials {
    font-size: 3rem;
    gap: 0.5rem;
    flex-direction: column;
  }
  .icon {
    display: none;
  }
  .newAdd {
    display: block;
    font-weight: 600;
    font-size: 0.8rem;
  }
  .newAdd2 {
    display: block;
    font-weight: 100;
    font-size: 0.8rem;
    text-align: center;
  }
}
