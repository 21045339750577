.main {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  background-color: rgba(0, 0, 0, 0.902);
  z-index: 100;
  /* border: 2px dashed green; */
  height: 100vh;
  display: flex;
  align-items: start;
  justify-content: center;
  /* backdrop-filter: blur(5px); */
  display: none;
}

.show {
  display: flex;
}

.menu {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  margin-top: 10rem;
}

.close {
  color: white;
  position: absolute;
  top: 2rem;
  left: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
  font-size: 1.5rem;
}

.main h2 {
  color: white;
  text-transform: uppercase;
  text-decoration: none;
}

@media screen and (max-width: 430px) {
  .main {
    width: 100%;
  }
  .close {
    top: 1rem;
    left: 1rem;
  }
}
