.main {
  display: flex;
  height: 100vh;
  position: relative;
}

.left,
.right {
  flex: 1;
}

.left {
  background-color: rgb(15, 15, 15);
  /* position: relative; */
  overflow: hidden;
}

.background {
  object-fit: cover;
  transform: scale(1);
  /* margin-top: -5rem; */
  /* margin-right: -1rem; */

  width: 100%;
  height: 100%;
}

.title {
  color: white;

  font-family: "ThisFont", serif;
  text-transform: uppercase;
  /* transform: rotate(90deg); */

  /* transform-origin: left top; */

  font-size: 4rem;
  width: 300px;
  line-height: 4rem;
}

.titleContainer {
  position: absolute;
  height: 10px;
  width: 10px;
  /* top: 16rem;
  left: -3rem; */
  z-index: 88;
  transform: rotate(90deg) translate(-60%, -600%);
  transform: rotate(90deg) translate(170px, -270px);

  transform-origin: left top;
  /* background-color: purple; */
}

.right {
  background-color: rgb(158, 158, 158);
  background-color: black;

  display: flex;
  flex-direction: column;
  align-items: start;

  justify-content: start;
  padding-top: 5rem;

  position: relative;
  /* overflow: scroll; */
  /* padding: 3rem; */
  /* max-height: 70vh; */
}
.menuContainer {
  max-height: 80vh;
  overflow: scroll;
  padding-left: 3rem;
}

.subtitle {
  color: white;
  text-transform: uppercase;
  padding: 2rem 0;
  font-family: "ThisFont";
}

.menuBox {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  /* padding: 0 3rem; */
  max-width: 700px;
  /* margin-top: 10rem; */
  /* overflow: scroll; */
  /* max-height: 70vh; */
  /* color: white;
  flex-direction: column; */
}

@media screen and (max-width: 744px) {
  .right {
  }
  .menuBox {
    /* height: 70%; */
    /* border: 1px solid white; */
    overflow: scroll;
    gap: 1rem;
    justify-content: left;
    /* margin-top: 8rem; */
  }
}

@media screen and (max-width: 430px) {
  .main {
    flex-direction: column-reverse;
    height: 92vh;
    position: relative;
  }
  .menuBox {
    width: 60%;
    /* border: 1px solid white; */
    justify-content: center;
    /* height: 66%; */
    /* margin-top: 6rem; */
  }
  .right {
    display: flex;
    justify-content: flex-end;
    height: 60%;
  }
  .left {
    /* height: 50%; */
  }
  .title {
    /* position: absolute; */
    /* left: -5rem; */
    font-size: 1.8rem;
    line-height: 2.2rem;
    width: 150px;
    display: none;
    /* height: 50%; */
  }
  .left {
    /* position: static; */
  }
  .titleContainer {
    position: absolute;
    display: flex;
    height: 10px;
    width: 10px;
    top: 300px;
    /* top: 16rem;
    left: -3rem; */
    z-index: 88;
    transform: rotate(90deg) translate(-60%, -600%);
    transform: rotate(90deg) translate(-200px, -180px);
  }
  .subtitle {
    font-size: 1.5rem;
    padding: 2rem 0;
  }
}


/*--------------------------*/

.main {
  display: flex;
  height: 100vh;
  position: relative;
}

.left,
.right {
  flex: 1;
}

.left {
  background-color: rgb(15, 15, 15);
  /* position: relative; */
  overflow: hidden;
}

.background {
  object-fit: cover;
  transform: scale(1);
  /* margin-top: -5rem; */
  /* margin-right: -1rem; */

  width: 100%;
  height: 100%;
}

.title {
  color: white;

  font-family: "ThisFont", serif;
  text-transform: uppercase;
  /* transform: rotate(90deg); */

  /* transform-origin: left top; */

  font-size: 4rem;
  width: 300px;
  line-height: 4rem;
}

.titleContainer {
  position: absolute;
  height: 10px;
  width: 10px;
  /* top: 16rem;
  left: -3rem; */
  z-index: 88;
  transform: rotate(90deg) translate(-60%, -600%);
  transform: rotate(90deg) translate(170px, -270px);

  transform-origin: left top;
  /* background-color: purple; */
}

.right {
  background-color: rgb(158, 158, 158);
  background-color: black;

  display: flex;
  flex-direction: column;
  align-items: start;

  justify-content: start;
  padding-top: 5rem;

  position: relative;
  /* overflow: scroll; */
  /* padding: 3rem; */
  /* max-height: 70vh; */
}
.menuContainer {
  max-height: 80vh;
  overflow: scroll;
  padding-left: 3rem;
}

.subtitle {
  color: white;
  text-transform: uppercase;
  padding: 2rem 0;
  font-family: "ThisFont";
}

.menuBox {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  /* padding: 0 3rem; */
  max-width: 700px;
  /* margin-top: 10rem; */
  /* overflow: scroll; */
  /* max-height: 70vh; */
  /* color: white;
  flex-direction: column; */
}

@media screen and (max-width: 744px) {
  .right {
  }
  .menuBox {
    /* height: 70%; */
    /* border: 1px solid white; */
    overflow: scroll;
    gap: 1rem;
    justify-content: left;
    /* margin-top: 8rem; */
  }
}

@media screen and (max-width: 430px) {
  .main {
    flex-direction: column-reverse;
    height: 92vh;
    position: relative;
  }
  .menuBox {
    width: 80%;
    /* border: 1px solid white; */
    justify-content: center;
    overflow:visible;
    /* height: 66%; */
    /* margin-top: 6rem; */
  }
  .right {
    display: flex;
    justify-content: flex-end;
    height: 60%;
  }
  .left {
    /* height: 50%; */
  }
  .title {
    /* position: absolute; */
    /* left: -5rem; */
    font-size: 1.8rem;
    line-height: 2.2rem;
    width: 150px;
    display: none;
    /* height: 50%; */
  }
  .left {
    /* position: static; */
  }
  .titleContainer {
    position: absolute;
    display: flex;
    height: 10px;
    width: 10px;
    top: 300px;
    /* top: 16rem;
    left: -3rem; */
    z-index: 88;
    transform: rotate(90deg) translate(-60%, -600%);
    transform: rotate(90deg) translate(-200px, -180px);
  }
  .subtitle {
    font-size: 1.5rem;
    padding: 2rem 0;
  }
}
