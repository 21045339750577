.main {
  display: flex;
  height: 100vh;
}

.left,
.right {
  flex: 1;
}

.left {
  background-color: darkslategray;
  position: relative;
  overflow: hidden;
}

.background {
  width: 120%;
  object-fit: cover;
  transform: scale(1);
  /* margin-top: -5rem;
  margin-right: -1rem; */

  width: 100%;
  height: 100%;
}

.right {
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.bio {
  width: 60%;
  color: white;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 744px) {
  .bio {
    width: 80%;
  }
}
@media screen and (max-width: 430px) {
  .logo {
    justify-content: flex-end;
  }
  .main {
    flex-direction: column-reverse;
  }
}
