.main {
  display: flex;
  height: 100vh;
}

.left,
.right {
  flex: 1;
}

.left {
  background-color: rgb(39, 39, 39);
  position: relative;
  overflow: hidden;
}

.background {
  width: 120%;
  object-fit: cover;
  transform: scale(1);
  /* margin-top: -5rem;
  margin-right: -1rem; */

  width: 100%;
  height: 100%;
}

.right {
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.bio {
  width: 60%;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.bioText {
  font-weight: 100;
}

@media screen and (max-width: 744px) {
  .bio {
    width: 80%;
  }
}
@media screen and (max-width: 430px) {
  .logo {
    justify-content: flex-end;
  }
  .main {
    flex-direction: column-reverse;
    height: 89vh;
  }
  .bio {
    padding-top: 5rem;
    font-size: 80% !important;
  }
  .bio h3 {
    font-size: 1rem;
  }
}
