* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Figtree", sans-serif;
}

body {
  background-color: black;
}

.App {
  position: relative;
}
